
    <template>
      <section class="content element-doc">
        <h2>Slider 滑块</h2>
<p>通过拖动滑块在一个固定区间内进行选择</p>
<h3>基础用法</h3>
<p>在拖动滑块时，显示当前值</p>
<demo-block>
        <div><p>通过设置绑定值自定义滑块的初始值</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;默认&lt;/span&gt;
    &lt;el-slider v-model=&quot;value1&quot;&gt;&lt;/el-slider&gt;
  &lt;/div&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;自定义初始值&lt;/span&gt;
    &lt;el-slider v-model=&quot;value2&quot;&gt;&lt;/el-slider&gt;
  &lt;/div&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;隐藏 Tooltip&lt;/span&gt;
    &lt;el-slider v-model=&quot;value3&quot; :show-tooltip=&quot;false&quot;&gt;&lt;/el-slider&gt;
  &lt;/div&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;格式化 Tooltip&lt;/span&gt;
    &lt;el-slider v-model=&quot;value4&quot; :format-tooltip=&quot;formatTooltip&quot;&gt;&lt;/el-slider&gt;
  &lt;/div&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;禁用&lt;/span&gt;
    &lt;el-slider v-model=&quot;value5&quot; disabled&gt;&lt;/el-slider&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const value1 = ref(0)
      const value2 = ref(50)
      const value3 = ref(36)
      const value4 = ref(48)
      const value5 = ref(42)

      const formatTooltip = (val) =&gt; {
        return val / 100
      }

      return {
        value1,
        value2,
        value3,
        value4,
        value5,
        formatTooltip
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>离散值</h3>
<p>选项可以是离散的</p>
<demo-block>
        <div><p>改变<code>step</code>的值可以改变步长，通过设置<code>show-stops</code>属性可以显示间断点</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;不显示间断点&lt;/span&gt;
    &lt;el-slider v-model=&quot;value1&quot; :step=&quot;10&quot;&gt; &lt;/el-slider&gt;
  &lt;/div&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;显示间断点&lt;/span&gt;
    &lt;el-slider v-model=&quot;value2&quot; :step=&quot;10&quot; show-stops&gt; &lt;/el-slider&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const value1 = ref(0)
      const value2 = ref(0)

      return {
        value1,
        value2
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>带有输入框</h3>
<p>通过输入框设置精确数值</p>
<demo-block>
        <div><p>设置<code>show-input</code>属性会在右侧显示一个输入框</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;el-slider v-model=&quot;value&quot; show-input&gt; &lt;/el-slider&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const value = ref(0)

      return {
        value
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>范围选择</h3>
<p>支持选择某一数值范围</p>
<demo-block>
        <div><p>设置<code>range</code>即可开启范围选择，此时绑定值是一个数组，其元素分别为最小边界值和最大边界值</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;el-slider v-model=&quot;value&quot; range show-stops :max=&quot;10&quot;&gt; &lt;/el-slider&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      const data = reactive({
        value: [4, 8]
      })
      return {
        ...toRefs(data)
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>竖向模式</h3>
<demo-block>
        <div><p>设置<code>vertical</code>可使 Slider 变成竖向模式，此时必须设置高度<code>height</code>属性</p>
</div>
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;el-slider v-model=&quot;value&quot; vertical height=&quot;200px&quot;&gt; &lt;/el-slider&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const value = ref(0)

      return {
        value
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>展示标记</h3>
<demo-block>
        <div><p>设置 <code>marks</code> 属性可以展示标记</p>
</div>
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;el-slider v-model=&quot;value&quot; range :marks=&quot;marks&quot;&gt; &lt;/el-slider&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      const data = reactive({
        value: [30, 60],
        marks: {
          0: '0°C',
          8: '8°C',
          37: '37°C',
          50: {
            style: {
              color: '#1989FA'
            },
            label: &lt;strong&gt;50%&lt;/strong&gt;
          }
        }
      })

      return {
        ...toRefs(data)
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>modelValue / v-model</td>
<td>绑定值</td>
<td>number</td>
<td>—</td>
<td>0</td>
</tr>
<tr>
<td>min</td>
<td>最小值</td>
<td>number</td>
<td>—</td>
<td>0</td>
</tr>
<tr>
<td>max</td>
<td>最大值</td>
<td>number</td>
<td>—</td>
<td>100</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>step</td>
<td>步长</td>
<td>number</td>
<td>—</td>
<td>1</td>
</tr>
<tr>
<td>show-input</td>
<td>是否显示输入框，仅在非范围选择时有效</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>show-input-controls</td>
<td>在显示输入框的情况下，是否显示输入框的控制按钮</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>input-size</td>
<td>输入框的尺寸</td>
<td>string</td>
<td>large / medium / small / mini</td>
<td>small</td>
</tr>
<tr>
<td>show-stops</td>
<td>是否显示间断点</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>show-tooltip</td>
<td>是否显示 tooltip</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>format-tooltip</td>
<td>格式化 tooltip message</td>
<td>function(value)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>range</td>
<td>是否为范围选择</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>vertical</td>
<td>是否竖向模式</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>height</td>
<td>Slider 高度，竖向模式时必填</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>label</td>
<td>屏幕阅读器标签</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>debounce</td>
<td>输入时的去抖延迟，毫秒，仅在<code>show-input</code>等于 true 时有效</td>
<td>number</td>
<td>—</td>
<td>300</td>
</tr>
<tr>
<td>tooltip-class</td>
<td>tooltip 的自定义类名</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>marks</td>
<td>标记， key 的类型必须为 number 且取值在闭区间 <code>[min, max]</code> 内，每个标记可以单独设置样式</td>
<td>object</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>change</td>
<td>值改变时触发（使用鼠标拖曳时，只在松开鼠标后触发）</td>
<td>改变后的值</td>
</tr>
<tr>
<td>input</td>
<td>数据改变时触发（使用鼠标拖曳时，活动过程实时触发）</td>
<td>改变后的值</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_slider = _resolveComponent("el-slider")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "默认"),
      _createVNode(_component_el_slider, {
        modelValue: _ctx.value1,
        "onUpdate:modelValue": $event => (_ctx.value1 = $event)
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "自定义初始值"),
      _createVNode(_component_el_slider, {
        modelValue: _ctx.value2,
        "onUpdate:modelValue": $event => (_ctx.value2 = $event)
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "隐藏 Tooltip"),
      _createVNode(_component_el_slider, {
        modelValue: _ctx.value3,
        "onUpdate:modelValue": $event => (_ctx.value3 = $event),
        "show-tooltip": false
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "格式化 Tooltip"),
      _createVNode(_component_el_slider, {
        modelValue: _ctx.value4,
        "onUpdate:modelValue": $event => (_ctx.value4 = $event),
        "format-tooltip": _ctx.formatTooltip
      }, null, 8, ["modelValue", "onUpdate:modelValue", "format-tooltip"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "禁用"),
      _createVNode(_component_el_slider, {
        modelValue: _ctx.value5,
        "onUpdate:modelValue": $event => (_ctx.value5 = $event),
        disabled: ""
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const value1 = ref(0)
      const value2 = ref(50)
      const value3 = ref(36)
      const value4 = ref(48)
      const value5 = ref(42)

      const formatTooltip = (val) => {
        return val / 100
      }

      return {
        value1,
        value2,
        value3,
        value4,
        value5,
        formatTooltip
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_slider = _resolveComponent("el-slider")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "不显示间断点"),
      _createVNode(_component_el_slider, {
        modelValue: _ctx.value1,
        "onUpdate:modelValue": $event => (_ctx.value1 = $event),
        step: 10
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "显示间断点"),
      _createVNode(_component_el_slider, {
        modelValue: _ctx.value2,
        "onUpdate:modelValue": $event => (_ctx.value2 = $event),
        step: 10,
        "show-stops": ""
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const value1 = ref(0)
      const value2 = ref(0)

      return {
        value1,
        value2
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_slider = _resolveComponent("el-slider")

  return (_openBlock(), _createBlock("div", { class: "block" }, [
    _createVNode(_component_el_slider, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": $event => (_ctx.value = $event),
      "show-input": ""
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const value = ref(0)

      return {
        value
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_slider = _resolveComponent("el-slider")

  return (_openBlock(), _createBlock("div", { class: "block" }, [
    _createVNode(_component_el_slider, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": $event => (_ctx.value = $event),
      range: "",
      "show-stops": "",
      max: 10
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ]))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const data = reactive({
        value: [4, 8]
      })
      return {
        ...toRefs(data)
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_slider = _resolveComponent("el-slider")

  return (_openBlock(), _createBlock("div", { class: "block" }, [
    _createVNode(_component_el_slider, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": $event => (_ctx.value = $event),
      vertical: "",
      height: "200px"
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const value = ref(0)

      return {
        value
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_slider = _resolveComponent("el-slider")

  return (_openBlock(), _createBlock("div", { class: "block" }, [
    _createVNode(_component_el_slider, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": $event => (_ctx.value = $event),
      range: "",
      marks: _ctx.marks
    }, null, 8, ["modelValue", "onUpdate:modelValue", "marks"])
  ]))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const data = reactive({
        value: [30, 60],
        marks: {
          0: '0°C',
          8: '8°C',
          37: '37°C',
          50: {
            style: {
              color: '#1989FA'
            },
            label: <strong>50%</strong>
          }
        }
      })

      return {
        ...toRefs(data)
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  